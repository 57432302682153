import { actions } from "./actions";
import axios from "axios";
// const baseUrlStoreApi = "http://localhost:7000"
const baseUrlStoreApi = "https://restapi.checkmehere.xyz"
export const dispatch = async (action: string, body?: Object, headerParams?: any) => {
    try{
        let response = {} as any
    let axiosOptions = {} as any
    switch(action){
        case actions.createStore:
            axiosOptions = getAxiosOptions("POST", baseUrlStoreApi, body)
            response = await axios(axiosOptions)
            return response.data
        case actions.getStores:
            axiosOptions = getAxiosOptions("GET", baseUrlStoreApi)
            response = await axios(axiosOptions)
            return response.data
        case actions.deleteStore :
            axiosOptions =  getAxiosOptions("DELETE", `${baseUrlStoreApi}/${headerParams.id}`)
            response = await axios(axiosOptions)
            return response.data
        case actions.getAllOrders:
            axiosOptions = getAxiosOptions("POST", `${baseUrlStoreApi}/getOrdersAny`, body)
            response = await axios(axiosOptions)
            return response.data
        case actions.getOrderItems:
            axiosOptions = getAxiosOptions("POST", `${baseUrlStoreApi}/getOrderItems`, body)
            response = await axios(axiosOptions)
            return response.data
        case actions.getProduct:
            axiosOptions = getAxiosOptions("POST", `${baseUrlStoreApi}/getProductJson`, body)
            response = await axios(axiosOptions)
            return response.data.product

    }

    }   
    catch(err: any){
        console.log(err)
        return err.response.data
    }
}
const getAxiosOptions = (method: string, url: string, body?: Object) => {
    const headers = {
        "Content-Type": "application/json",
    };
    switch (method) {
        case "GET":
            return {
                method: "GET",
                url,
                headers,
            };
            break;
        case "POST":
            return {
                method: "POST",
                url,
                headers,
                data: body,
            };
            break;
        case "PUT":
            return {
                method: "PUT",
                url,
                headers,
                data: body,
            };
            break;
        case "DELETE":
            return {
                method: "DELETE",
                url,
                headers,
            };
            break;
    }
};