import { FC } from "react";
import { IProductTableData } from "../../interfaces/formData/ProductTableData.interface";
import "./Product.component.css";
interface IProductProps {
    product: IProductTableData;
}
export const Product: FC<IProductProps> = ({ product }) => {
    console.log(product)
    return (
        <div className="product">
            <img
                className="product__image"
                src={`${product.MainImage}`}
                alt="product"
            />
            <div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Status:</b>
                    {product.Status}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Name:</b>
                    {product.Name}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Sku:</b>
                    {product.Sku}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Price:</b>
                    {product.ItemPrice}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Brand:</b>
                    {product.Brand}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Variation:</b>
                    {product.Variation}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Primary Category:</b>
                    {product.PrimaryCategory}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Tracking Code:</b>
                    {product.TrackingCode}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Created At:</b>
                    {product.CreatedAt}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Ordered:</b>
                    {product.pendingSince} ago
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Shipment Method:</b>
                    {product.ShipmentMethod}
                </div>
                <div>
                    <b style={{ marginRight: "1rem" }}>Shipment Provider:</b>
                    {product.ShipmentProvider}
                </div>
                <div>
                    <a href={product.Url} style={{ marginRight: "1rem" }}>
                        URL
                    </a>
                </div>
                {product.Reason && (
                    <div>
                        <b style={{ marginRight: "1rem" }}>Reason:</b>
                        {product.Reason}
                    </div>
                )}
                {product.ReasonDetail && (
                    <div>
                        <b style={{ marginRight: "1rem" }}>Reason Details:</b>
                        {product.ReasonDetail}
                    </div>
                )}
            </div>
        </div>
    );
};
