import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { actions } from '../dispatch';
import { dispatch } from '../dispatch/dispatch';
import { StoreFormData } from '../interfaces';
import { IErrorResponse } from '../interfaces/responses/ErrorResponse.interface';
import { IStoreResponseData } from '../interfaces/responses/StoreResponseData.interface';

interface IAddStoreProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  stores: Array<IStoreResponseData>
  setStores: Dispatch<SetStateAction<Array<IStoreResponseData>>>
}

export const AddStoreModal: FC<IAddStoreProps> = ({ setShowModal, stores, setStores }) => {
  const ref = useRef<HTMLFormElement>(null);
  const onClickCancel = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowModal(false);
  };
  const onClickSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const storeResponse: IStoreResponseData | IErrorResponse = await dispatch(actions.createStore, {...formData})
    if("error" in storeResponse){
      return console.log(storeResponse.error.message)
    }
    const vStores = [...stores]
    vStores.push(storeResponse)
    setStores(vStores)
    setShowModal(false);
  };
  const [showForm, setShowForm] = useState<boolean>(true);
  const [formData, setFormData] = useState<StoreFormData>({
    name: '',
    UserID: '',
    API_KEY: '',
  });
  const onChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showForm && ref.current && !ref.current.contains(e.target)) {
        setShowForm(false);
        setShowModal(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showForm]);

  return ReactDOM.createPortal(
    <div className='modal'>
      <form ref={ref} className='modal__container'>
        <div className='modal__header'>Add Store</div>
        <div className='modal__body'>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Name</div>
            <input
              className='modal__input'
              type='text'
              name='name'
              value={formData.name}
              onChange={onChangeFormData}
            />
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>User Id</div>
            <input
              className='modal__input'
              type='text'
              name='UserID'
              value={formData.UserID}
              onChange={onChangeFormData}
            />
          </div>
          <div className='modal__inputContainer'>
            <div className='modal__label'>Api Key</div>
            <input
              className='modal__input'
              type='text'
              name='API_KEY'
              value={formData.API_KEY}
              onChange={onChangeFormData}
            />
          </div>
        </div>
        <div className='modal__footer'>
          <button
            className='modal__button-margin button bg-secondaryWhite'
            onClick={onClickCancel}>
            Cancel
          </button>
          <button
            className='modal__button button bg-blue'
            onClick={onClickSubmit}>
            Add
          </button>
        </div>
      </form>
    </div>,
    document.getElementById('addStore')!
  );
};
