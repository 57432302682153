import { useState, useEffect } from 'react';
import './App.css';
import { Header, HeroInput, Product } from './component';
import { Spinkit } from './component/spinkit/Spinkit';
import { actions } from './dispatch';
import { dispatch } from './dispatch/dispatch';
import { IProductTableData } from './interfaces/formData/ProductTableData.interface';
import { IErrorResponse } from './interfaces/responses/ErrorResponse.interface';
import { IOrderItemResponse } from './interfaces/responses/OrderItemResponse.interface';
import { IOrderResponse } from './interfaces/responses/OrderResponse.interface';
import { IProductResponse } from './interfaces/responses/ProductResponse.interface';
import { IStoreResponseData } from './interfaces/responses/StoreResponseData.interface';

function App() {
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [stores, setStores] = useState<Array<IStoreResponseData>>([])
  const [selectedStore, setSelectedStore] = useState<IStoreResponseData>({} as IStoreResponseData)
  const [orderNumber,setOrderNumber] = useState<string>("")
  const [error, setError] = useState("")
  const [products, setProducts] = useState<Array<IProductTableData>>([])
  const [statuses, setStatuses] = useState<Array<string>>([])
  const [eraseError, setEraseError] = useState<boolean>(false)
  useEffect(() => {
    (async () => {
    if(!selectedStore._id){
      return setError("Please Select a store")
    }
    else if(error === "Please Select a store"){
      setError("")
    }
    if(orderNumber.length === 0){
      return 
    }
    if(selectedStore._id){
      setError("")
    }
    if(orderNumber.trim().length === 9 && orderNumber.trim().search(/[0-9]{9}/g) !== -1){
      setShowSpinner(true)
      const currentTime = new Date(Date.now())
      const createdBefore = new Date(Date.now())
      currentTime.setDate(currentTime.getDate() - 45)

      const createdAfter = currentTime
      console.log(createdAfter.toISOString())
      const allOrders: Array<IOrderResponse> | IErrorResponse = await dispatch(actions.getAllOrders, {createdBefore: createdBefore.toISOString(), createdAfter: createdAfter.toISOString(), UserID: selectedStore.UserID, API_KEY: selectedStore.API_KEY})
      console.log(allOrders)
      if("error" in allOrders){
        setShowSpinner(false)
        return console.log(allOrders.error.message)
      }
      const order = allOrders.find(order => order.OrderNumber === orderNumber.trim())
      if(!order){
        setShowSpinner(false)
        setProducts([])
        setOrderNumber("")
        return setError("Order Not Found with this Order Number: " + orderNumber)
      }
      let orderItems: Array<IOrderItemResponse> | IOrderItemResponse | IErrorResponse = await dispatch(actions.getOrderItems, {OrderId: order.OrderId, UserID: selectedStore.UserID, API_KEY: selectedStore.API_KEY})
      if("error" in orderItems){
        setShowSpinner(false)
        return console.log(orderItems.error.message)
      }
      if(!Array.isArray(orderItems)){
        orderItems = [{...orderItems}]
      }
      setStatuses(orderItems.map(item => item.Status))
      const vProducts = []
      for(const item of orderItems){
        const vProduct = {} as IProductTableData
        let product : IProductResponse | IErrorResponse | undefined | Array<IProductResponse> = await dispatch(actions.getProduct, {SKU: item.Sku, UserID: selectedStore.UserID, API_KEY: selectedStore.API_KEY})
        if(product){
          console.log(product)
          if("error" in product){
            setShowSpinner(false)
            return console.log(product.error.message)
          }
          if(Array.isArray(product)){
            product = product[0]
          }
          vProduct.Sku = item.Sku
          vProduct.ShipmentMethod = item.ShipmentMethod
          vProduct.ShipmentProvider = item.ShipmentProvider
          vProduct.CreatedAt = item.CreatedAt
          vProduct.TrackingCode = item.TrackingCode
          vProduct.Status = item.Status
          vProduct.Url = product.Url
          vProduct.MainImage = product.MainImage
          vProduct.Name = product.Name
          vProduct.Brand = product.Brand
          vProduct.Variation = product.Variation
          vProduct.PrimaryCategory = product.PrimaryCategory
          vProduct.ItemPrice = item.ItemPrice
          vProduct.Reason = item.Reason
          vProduct.ReasonDetail = item.ReasonDetail
          const orderDate = new Date(vProduct.CreatedAt)

          const currentDate = new Date(Date.now())
          let diff =  currentDate.getTime() - orderDate.getTime()
          const hour = 60 * 60 * 1000
          const days = Math.floor(Math.abs(diff) / (hour * 24))
          diff = Math.abs(diff) % (hour * 24)
          const hours = Math.floor(Math.abs(diff) / hour)
          vProduct.pendingSince = `${days}d ${hours}h`
          if(vProduct.Status === "pending" || vProduct.Status === "canceled"){
            setOrderNumber("")
            setShowSpinner(false)
            setProducts([])
            return setError(`The Order Status for ${orderNumber.trim()} is "${vProduct.Status}"`)
          }
          vProducts.push(vProduct)
        }
      }
      setProducts(vProducts)
      setShowSpinner(false)
      setOrderNumber("")
    }
    else{
      setShowSpinner(false)
      setProducts([])
      return setError("Incorrect Order Number Format")
    }
  
    })()
  },[orderNumber, selectedStore])

  return (
    <div className='App'>
      
      {showSpinner && <Spinkit />}
      <Header stores={stores} setStores={setStores} selectedStore={selectedStore} setSlectedStore={setSelectedStore} setShowSpinner={setShowSpinner}/>
      <HeroInput orderNumber={orderNumber} setOrderNumber={setOrderNumber} error={error} setError={setError} statuses={statuses}/>
      <div className='product__list'>
        
        {products.map((product) => (
          <Product product={product}/>
        ))}
      </div>
    </div>
  );
}

export default App;
