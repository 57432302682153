import { FC, Fragment, useState, useContext, useEffect, useRef , Dispatch, SetStateAction} from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import './Header.component.css';
import { AddStoreModal } from '../../modals';
import { IStoreResponseData } from '../../interfaces/responses/StoreResponseData.interface';
import { IErrorResponse } from '../../interfaces/responses/ErrorResponse.interface';
import { dispatch } from '../../dispatch/dispatch';
import { actions } from '../../dispatch';
import { IDeleteResponse } from '../../interfaces/responses/DeleteResponse.interface';
interface IHeaderProps {
  stores: Array<IStoreResponseData>;
  setStores: Dispatch<SetStateAction<Array<IStoreResponseData>>>;
  selectedStore: IStoreResponseData;
  setSlectedStore: Dispatch<SetStateAction<IStoreResponseData>>;
  setShowSpinner: Dispatch<SetStateAction<boolean>>
}
export const Header: FC<IHeaderProps> = ({stores, setStores, selectedStore, setSlectedStore, setShowSpinner}) => {
  const [expandDropdown, setExpandDropdown] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [storesUpdate, setStoresUpdate] = useState<number>(0)
  useEffect(() => {
    (async () => {
      setShowSpinner(true)
      const stores: Array<IStoreResponseData>| IErrorResponse = await dispatch(actions.getStores)
      if("error" in stores){
        setShowSpinner(false)
        return console.log(stores.error.message)
      }
      setStores(stores)
      setShowSpinner(false)
    })()
  },[storesUpdate])
  const onClickDelete = async (storeId: string) => {
    const storeDeleteResponse: IDeleteResponse | IErrorResponse = await dispatch(actions.deleteStore, {}, {id: storeId})
    if("error" in storeDeleteResponse){
      return console.log(storeDeleteResponse.error.message)
    }
    setStoresUpdate(storesUpdate + 1)
    setExpandDropdown(!expandDropdown)
  }
  // const stores = [
  //   { name: 'awdawd', id: 1 },
  //   { name: 'awcawd', id: 2 },
  //   { name: 'awaawd', id: 3 },
  //   { name: 'awsawd', id: 4 },
  //   { name: 'aaaawd', id: 5 },
  // ];

  return (
    <Fragment>
      <div className='header'>
        <div className='header__logo'>
          <span className='accent'>Order</span>
          <span className='secondary'>Scanner</span>
        </div>
        <div className='header__activeStore'>{selectedStore.name ? selectedStore.name : "Active Store"}</div>
        <div className='header__storeDropdown'>
          <div
            className='header__storeDropdownButton'
            onClick={() => {
              setExpandDropdown(!expandDropdown);
            }}>
            <div>{ "Available Shops"}</div>

            {expandDropdown ? (
              <ArrowDropUpIcon className='large__svg' />
            ) : (
              <ArrowDropDownIcon className='large__svg' />
            )}
          </div>
          {expandDropdown && (
            <div className='header__availableStoreListContainer'>
              <div className='header__availableStoreList'>
                {stores.map((store) => (
                  <>
                    <div>{store.name}</div>
                    <div className='button bg-blue' onClick={() => {
                      setSlectedStore(store)
                      setExpandDropdown(!expandDropdown)
                    }}>
                      Select
                    </div>
                    <div className='button bg-danger' onClick={() => {
                      onClickDelete(store._id)
                    }}>Delete</div>
                  </>
                ))}
              </div>
              <div className='header__availableStoreButtonContainer'>
                <div
                  className='header__availableStoreButton button bg-blue'
                  onClick={() => {
                    setShowModal(true);
                  }}>
                  Add Store
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && <AddStoreModal setShowModal={setShowModal} stores={stores} setStores={setStores}/>}
    </Fragment>
  );
};
