import { FC, Fragment, Dispatch, SetStateAction, useState } from 'react';
import { IStoreResponseData } from '../../interfaces/responses/StoreResponseData.interface';
import './HeroInput.component.css';
interface IHeroInputProps{
  orderNumber: string;
  setOrderNumber: Dispatch<SetStateAction<string>>;
  error: string
  setError: Dispatch<SetStateAction<string>>
  statuses: Array<string>

}
export const HeroInput: FC<IHeroInputProps> = ({orderNumber, setOrderNumber, error, setError, statuses}) => {
  return (
    <Fragment>
      <form className='hero-input__container'>
        <div >
          Statuses: {statuses.map(status => {
            switch(status){
              case"returned": case "pending": case "canceled":
               return `${status} ⛔,`
              case("ready_to_ship"):
                return `${status} ✅,`
              default:
                return <text  style={{color: "blue"}}>{`${status} ,`}</text>
            }
          })}
        </div>
        <div>
          Number of Items: <text style={{color: "red"}}>{statuses.length}</text>
        </div>
        {error && (
          <div className='error-message' >
            {error}
          </div>
        )}
        <input className='hero-input' value={orderNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setOrderNumber(e.target.value)
        }} onKeyDown={(e:any) => {
          if(e.keyCode === 13){
            e.preventDefault()
          }
        }}/>
      </form>
    </Fragment>
  );
};
